import axiosClient from '../axiosClient';

const getToken = () =>
  (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
  undefined;

const baseURL = process.env.REACT_APP_API + 'v2/admin-panel/';
const authHeader = () => ({ Authorization: 'Bearer ' + getToken() });

const getNotis = (params, thunkAPI) =>
  axiosClient.get('notification', {
    signal: thunkAPI?.signal,
    params,
    baseURL,
    headers: authHeader(),
  });

const getNotiDetail = (id, thunkAPI) =>
  axiosClient.get(`notification/${id}`, {
    signal: thunkAPI?.signal,
    baseURL,
    headers: authHeader(),
  });

const postNoti = (data) =>
  axiosClient.post('notification', data, {
    baseURL,
    headers: authHeader(),
  });

const putNoti = (id, data) =>
  axiosClient.patch(`notification/${id}`, data, {
    baseURL,
    headers: authHeader(),
  });

const putStatusNoti = (data) =>
  axiosClient.patch(`notification/${data?.id}/status/${data?.values?.status}`, {
    baseURL,
    headers: authHeader(),
  });

const exportNoti = (params, options) =>
  axiosClient.get('notification/export', {
    params,
    ...options,
    baseURL,
    headers: authHeader(),
  });

const putNotiImage = (id, data) =>
  axiosClient.patch(`notification/${id}`, data, {
    baseURL,
    headers: authHeader(),
  });

const deleteNotiImage = (id, fileUploadId) =>
  axiosClient.delete(`notification/${id}/picture/${fileUploadId}`, {
    baseURL,
    headers: authHeader(),
  });

export {
  getNotis,
  getNotiDetail,
  postNoti,
  putNoti,
  putStatusNoti,
  exportNoti,
  putNotiImage,
  deleteNotiImage,
};
